<template>
  <div
    class="h-full pt-7 flex flex-col justify-between z-40 bg-neutral-white overflow-auto"
    v-bind="$attrs"
    :class="{
      'w-[63px]': collapsed,
    }"
  >
    <div>
      <slot name="close" />
      <section
        :class="{
          'px-5': !collapsed,
          'px-2': collapsed,
        }"
      >
        <div class="flex items-center justify-between mb-4 flex-row-reverse">
          <!-- TODO: replace with real avatar component -->
          <PulseOptionsMenu
            :options="[
              {
                color: 'text-typography-primary',
                icon: 'LineMinusCircle',
                label: 'Logout',
                value: 'logout',
              },
            ]"
            @logout="logout"
          >
            <UserAvatar v-show="!collapsed" :image="picture" />
          </PulseOptionsMenu>
          <PulseCircleLogo class="h-8 w-8" />
        </div>
        <section class="mb-8">
          <SearchInput class="mb-4" />
          <StoreSelectButton @store-select:click="$emit('store-select:click')" />
        </section>
      </section>
      <section class="px-2">
        <NavItems @menu:click="$emit('menu:click')" />
      </section>
    </div>
    <div class="py-8">
      <NavItem v-if="!isGlobalSupport" :to="`/${currentStore?.slug}/macros`">
        <component :is="Icons.LineBolt" class="h-5 w-5" />
        <span v-if="!collapsed">Macros</span>
      </NavItem>
      <ThemeSwitch class="text-xsm px-4" :collapsed="collapsed" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import * as Icons from '@pulse/components/icons';
import { storeToRefs } from 'pinia';

import { useMerchantStore } from '~/store/merchant';
import { useUserStore } from '~/store/user';

const merchantStore = useMerchantStore();

const { allStores, currentStore } = storeToRefs(merchantStore);

defineProps({
  open: {
    default: false,
    type: Boolean,
  },
});

defineEmits(['menu:click', 'store-select:click']);

const store = useUserStore();

const { isGlobalSupport } = storeToRefs(store);

function logout() {
  store.logout();
  navigateTo('/login');
}

const picture = computed(() => store.user?.picture);
const collapsed = inject('collapsed', true);
</script>
